<template>
  <div class="wrapper flex flex-direction-col justify-center items-center h-full">
    <img src="@/assets/shared/biote-loader.svg" class="loader" />
    <div class="loading-text" v-if="!hideText">
      <h1 class="loading">
        <span>
          {{ value }}
        </span>
        <span class="dot-one"> .</span>
        <span class="dot-two"> .</span>
        <span class="dot-three"> .</span>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: {
    hideText: Boolean,
    value: {
      type: String,
      default: () => {
        return "LOADING ";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./LoadingScreen.scss";
</style>
