import Vue from "vue";
import { Auth0Plugin } from "@/auth";
import "./plugins/socialSharing";
import vueDebounce from "vue-debounce";
import Vuelidate from "vuelidate";
import VuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
// FontAwesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import VSelect from "vue-select";
import VueCalendly from "vue-calendly";

// import authService from "@/auth/auth.service";
import Antd from "ant-design-vue";
import Print from "vue-print-nb";
import FontAwesomeIcons from "./utils/font-awesome-icons";
import i18n from "./plugins/i18n";
import "ant-design-vue/dist/antd.css";
import routerGuard from "./router-guard";
import App from "./App.vue";
import store from "./store";
import router from "./new-router";
import axios from "./lib/axios";
import "./styles/_config.scss";

Vue.use(vueDebounce, {
  defaultTime: "700ms",
});
/**
 * VueLidate CONFIG
 */
Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
  i18n: false,
  // Define common validation messages.
  template: templates.singleErrorExtractor.foundation6,
  messages: {
    required: "{attribute} is required!",
    minLength: "Field {label} must be 5 symbols",
    minAge: "Minimum Age is 20 Years",
    ageLimit: "Maximum Age is 100 Years",
    min4Digits: "Must be 4 digits",
  },
});
// END

Vue.component("v-select", VSelect);

Vue.use(Print);
Vue.use(Antd);
Vue.use(VueCalendly);

library.add(FontAwesomeIcons);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.prototype.$internalAxios = axios;
Vue.config.productionTip = false;

// Initialize the authentication service
Vue.use(Auth0Plugin);

routerGuard(router);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");
